import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Kantumruy_Pro\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-kantumruy-pro\",\"display\":\"swap\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"kantumruyPro\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Urbanist\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-urbanist\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"urbanist\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ClientLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloProvider"] */ "/vercel/path0/src/configs/ApolloProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationProvider"] */ "/vercel/path0/src/notifications/useNotification.tsx");
